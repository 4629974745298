import React, { useState } from 'react';
import { navigate } from 'gatsby';
import useAuth from '../hooks/useAuth';

const Login = ({ redirect }) => {
    const { state, login } = useAuth();
    const [identifier, setIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await login({ identifier, password });
            navigate(redirect);
        } catch (e) {
            console.log('Error occurred during authentication');
            const {
                response: {
                    data: {
                        message: [
                            {
                                messages: [error],
                            },
                        ],
                    },
                },
            } = e;
            const { message: msg } = error;
            setError(msg);
        }
    };

    return (
        <>
            <pre>{JSON.stringify({ state }, null, 2)}</pre>

            <form onSubmit={handleSubmit} className='uk-form-stacked'>
                <label htmlFor='username' className='uk-form-label'>
                    Username
                </label>
                <div className='uk-inline'>
                    <span className='uk-form-icon' uk-icon='icon: user'></span>
                    <input
                        onChange={(e) => {
                            setIdentifier(e.target.value);
                        }}
                        value={identifier}
                        id='username'
                        type='text'
                        placeholder='Username'
                        className='uk-input'
                    />
                </div>
                <label htmlFor='password' className='uk-form-label'>
                    Password
                </label>
                <div className='uk-inline'>
                    <span className='uk-form-icon' uk-icon='icon: lock'></span>
                    <input
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        value={password}
                        id='password'
                        type='password'
                        placeholder='******************'
                        className='uk-input'
                    />
                </div>
                <button type='submit' className='uk-button uk-button-default'>
                    Sign In
                </button>
            </form>

            {error.length > 1 && <p>{error}</p>}
        </>
    );
};

export default Login;
